// To avoid detecting changes if month and date has preceeding "0" 
function normalizeDate(value) {
    if (typeof value === 'string') {
        const dateMatch = value.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/);
        if (dateMatch) {
            const [, year, month, day] = dateMatch;
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        }
    }
    return value;
}

// To properly check and compare specially arrays
function isEqual(a, b) {
    // Check for null/undefined and false cases
    if ((a == null && b === false) || (b == null && a === false)) {
        return true;
    }
    
    // Check if both are arrays
    if (Array.isArray(a) && Array.isArray(b)) {
        return a.length === b.length && a.every((val, index) => isEqual(val, b[index]));
    }
    return a === b;
  }


export const updatedFieldChecker = (oldData, newData) => {
    let updated = {};

    Object.keys(oldData).forEach(key => {
        // Deconstruct "address" object to properly do a comparison
        if (key === "address") {
            if (oldData.hasOwnProperty("address") && newData.hasOwnProperty("address")) {
                const oldDisplayAddress = oldData.address;
                const newDisplayAddress = newData.address.displayAddress || newData.address;
                
                if (oldDisplayAddress !== newDisplayAddress) {
                    updated[key] = { oldValue: oldDisplayAddress, newValue: newDisplayAddress };
                }
            }
        } 
        // Filter status since "clinic" has a boolean status while other user types have string status
        else if(key === "status") {
            if((typeof(oldData.status) === 'string' && typeof(newData.status) === 'string' && oldData.status !== newData.status) || (oldData.status === 'Active' && typeof(newData.status) === 'boolean' && newData.status)) {
                updated[key] = { oldValue: oldData.status, newValue: (newData.status || oldData.status == "Active") ? 'Active' : 'Inactive' };
            }

        } else {
            if (newData.hasOwnProperty(key)){
                const oldValue = normalizeDate(oldData[key]);
                const newValue = normalizeDate(newData[key]);

                if (!isEqual(oldValue, newValue)) {
                    updated[key] = { oldValue: oldData[key], newValue: newData[key] };
                }
            }
        }
    });

    return updated;
}
